import React from 'react'
import {Col, Container, Row, Spinner} from "react-bootstrap";

const LoadingSpinner = () => (
    <Container style={{marginTop: '35px', marginLeft: '80px'}}>
      <Row>
        <Col>
          <Spinner animation="border" role="status">
            <span className="sr-only">Buscando información...</span>
          </Spinner>
        </Col>
      </Row>
    </Container>
);

export default LoadingSpinner;