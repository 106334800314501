import React, { useContext } from 'react';
import { Row, Col, Container, Alert } from "react-bootstrap";

import wolData from '../common/wolData';
import { useFetch } from "react-async";
import LocaleContext from "./localeContext";
import LoadingSpinner from "./loadingSpinner";

import NewsletterForm from "./newsletterForm";
import ListItemJob from './ListItemJob';


const Offers = () => {
  const { countryCode } = useContext(LocaleContext);
  const { data, error, isPending } = useFetch(
    wolData.urls.homepageUrl + `/homepage-data.${countryCode}.json`,
    { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' } }
  );
  if (isPending) return <LoadingSpinner />
  if (error) {
    return <Alert variant="danger">
      <b>ERROR:</b> {error.message}
    </Alert>;
  }

  return (
    <>
      <Container id="homepage-jobs-container" >
        <Container fluid>
          <Row className="text-center justify-content-center align-self-center mb-5">
            <Col
              id="offers-tag"
              sm={4}
              lg={4}>
              <h2>Destacados</h2>
            </Col>
          </Row>
        </Container>

        <Row id="offer-list">
          {data.jobs
            .slice(0, 15) // TODO: Remove when adjust job items limit in API
            .filter(job => job.hide_on_homepage !== true)
            .slice(0, 8)
            .map((job, index) => (<ListItemJob job={job} index={index} key={`job-${index}`} />))}
        </Row>

        <div className="unblock-background">
          <div className='unblock'>
            <div>
              <img
                src="/icono-desbloquea.svg"
                alt='desbloquea-trabajos'
                width="85"
                height="115" />
            </div>
            <a id='unblock-link' href={wolData.urls.frontUrl}>
              <div className='unblock-text'>
                <span>En un sólo paso</span>
                <p>
                  Regístrate <span id="free">gratis</span> para
                  <br></br>
                  ver todas las vacantes
                </p>
              </div>
            </a>
          </div>
        </div>

      </Container>
      {wolData.validSites.includes(countryCode) ? <NewsletterForm site={countryCode} location={"home"} /> : null}
    </>
  )
}

export default Offers;