import React, { useContext, useEffect } from 'react';
import { Row, Col, Container } from "react-bootstrap";
import LocaleContext from "./localeContext";
import Offers from './Offers';
import BlogSection from './BlogSection';

const Home = () => {
  const { setShowSwitch } = useContext(LocaleContext);

  useEffect(() => { setShowSwitch(true) }, [setShowSwitch])

  return (
    <React.Fragment>
      <Container fluid id="banner-background" className="text-white">
        <Container fluid id="banner"></Container>
        <Container fluid id="inner">
          <Row>
            <Col md={12} lg={9}>
              <h1>
                <span className="small-title"> El lugar donde encuentras</span>
                <br></br>
                Los mejores trabajos
                <br></br>
                para abogados
              </h1>
              <div id="banner-link">
                <a href="#mail-offers-title">Recibe ofertas de trabajo en tu email</a>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>

      <Offers />
      <BlogSection />

      <Container fluid id="stats-section">
        <Container fluid id="stats">
          <Row>
            <Col>
              <div id="stats-figures-bg">
                <div id='stats-figures'>
                  <h3>25,000+ </h3> <span>Abogados</span>
                  <h3>190,000+ </h3>  <span> Postulaciones online</span>
                </div>
              </div>
            </Col>
            <Col className='d-flex'>
              <div id="stats-invitation">
                <h3>
                  ¿Quieres recibir las ofertas laborales de la semana?
                </h3>
                <a href="#mail-offers-title">Recibe empleos en tu email</a>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>

    </React.Fragment >
  );
}
export default Home;
