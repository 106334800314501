import React, { useContext, useEffect } from 'react';
import { useFetch } from "react-async";
import { useParams } from "react-router-dom";
import { Row, Col, Alert, Container, Dropdown } from 'react-bootstrap'
import { FacebookShareButton, WhatsappShareButton } from "react-share"
import { FaWhatsapp, FaFacebookSquare } from 'react-icons/fa';

import wolData from '../common/wolData';
import utils from '../common/utils';
import LocaleContext from "./localeContext";
import LoadingSpinner from "./loadingSpinner";
import Markup from './markup'
import NewsletterForm from "./newsletterForm";

const labels = {
  requirements: { 'en': 'Requirements', 'es': 'Requisitos' },
  shareButtonsIntro: { 'en': 'Share:', 'es': 'Compartir por:' },
  salary: { 'en': 'Salary', 'es': 'Remuneración' }
}

const normalizeData = (rawData, jobId) => {
  const job = rawData.jobs.find((j) => j.url === jobId)
  rawData["job"] = job
  rawData["employer"] = job.employer
  return rawData
}

const getApplyText = (job, labelsLanguage) => {
  if (labelsLanguage === 'es') {
    if (job.open_to_hire) {
      return 'Inscripción';
    } else {
      return 'Postular';
    }
  } else if (labelsLanguage === 'en') {
    if (job.open_to_hire) {
      return 'Inscription';
    } else {
      return 'Apply';
    }
  }
}

const Job = () => {
  const { setShowSwitch } = useContext(LocaleContext);
  useEffect(() => { setShowSwitch(false) }, [setShowSwitch])

  const { id } = useParams();
  const { data: _data, error, isPending } = useFetch(
    wolData.urls.homepageUrl + `/all-jobs.json`,
    { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' } }
  );
  if (isPending) return <LoadingSpinner />
  if (error) { return <Alert variant="danger"><b>ERROR:</b> {error.message}</Alert> }

  const jobExists = (_data.jobs.find((j) => j.url === id)) !== undefined;
  if (!jobExists) {
    return (
      <Container>
        <Alert variant={"danger"}>
          <Alert.Heading>Error:</Alert.Heading>
          <p>Oferta de trabajo <b>"{id}"</b> no existe</p>
        </Alert>
      </Container>
    );
  }

  const ShareJobDropdown = () => {
    return (
      <Dropdown>
        <Dropdown.Toggle variant="outline-light" className="btn btn-lg">
          Compartir
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item key={"whatsapp-share"}>
            <WhatsappShareButton url={window.location.href} title={data.job.title}>
              <FaWhatsapp /> WhatsApp
            </WhatsappShareButton>
          </Dropdown.Item>
          <Dropdown.Item key={"facebook-share"}>
            <FacebookShareButton url={window.location.href} quote={data.job.subtitle}>
              <FaFacebookSquare /> Facebook
            </FacebookShareButton>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  const data = normalizeData(_data, id);
  const labelsLanguage = (data.job.url === 's-s-litigios-cdmx') ? 'en' : 'es';

  return (
    <React.Fragment>

      {/* Banner */}
      <Row
        id="job-offer-banner-container"
        style={{
          backgroundImage: "url(/job-offer-background.jpg)",
          backgroundPosition: 'left',
          backgroundAttachment: 'cover'
        }}
      >
        <Col id="job-offer-banner" md={8} sm={9}>
          <span className='job-offer-subtitle'>{data.job.employer.name}</span>
          <h2>{data.job.title}</h2>

          {(data.job.open_to_hire !== true) ?
            <p className="job-offer-subtitle">{data.job.subtitle} {(data.job.city) ? ` - ${data.job.city}` : null}</p>
            :
            null
          }

          <br />
          <div className='d-flex align-items-left'>
            <a href={utils.getApplyUrl(data.job)} className="btn btn-danger btn-lg mr-4">{getApplyText(data.job, labelsLanguage)}</a>
            <ShareJobDropdown />
          </div>
        </Col>
        <Col xs={3} />
      </Row>

      <Row id='job-offer-description' className='mt-5'>
        <Col>

          {(data.job.open_to_hire !== true) ?
            <div>
              <h2 className='mb-3'>{labels.requirements[labelsLanguage]}</h2>
              <ul>
                {data.job.requirements.map((requirement, index) => (<li key={`requirement-${index}`}>{requirement}</li>))}
              </ul>
            </div> : null
          }

          {/* Salary */}
          {data.job.salary ?
            (<React.Fragment>
              <br />
              <h2>{labels.salary[labelsLanguage]}</h2>
              <hr />
              <ul>
                <li>{data.job.salary}</li>
              </ul>
            </React.Fragment>
            ) : null
          }
        </Col>
      </Row>

      <Markup
        data={data.job.content}
        options={{
          wrap: content => <div id="job-offer-content" style={{ marginTop: '20px' }}>{content}</div>
        }}
      />

      {/* TODO: Fix hardcoded info */}
      {data.job.url === "senior-stock-exchange-counselor-BBVA-2022" ?
        (<Row style={{ marginTop: '35px' }} id="job-offer-banner-container">
          <Col>
            <h2>Oferta de Valor</h2>
            <hr />
            <br />

            <h3 style={{ marginLeft: '15px', fontSize: '24px' }}>Beneficios</h3>
            <ul>
              <li>Prestaciones bancarias superiores a las de la ley.</li>
              <li>Seguro de vida.</li>
              <li>Vacaciones superiores.</li>
              <li>Plan de jubilación.</li>
              <li>Oportunidad de crecimiento.</li>
              <li>Promociones exclusivas para empleados.</li>
              <li>Préstamos exclusivos para empleados.</li>
              <li>Membresía a deportivos.</li>
              <li>Participación en los juegos bancarios.</li>
            </ul>
            <br />

            <h3 style={{ marginLeft: '15px', fontSize: '24px' }}>Diversidad</h3>
            <p style={{ marginLeft: '18px' }}>En BBVA estamos orgullosos de ser una empresa en la que prima la diversidad, la inclusión y la igualdad de oportunidades, independientemente del origen y condición de los empleados y colaboradores.</p>
            <br />

            <h3 style={{ marginLeft: '15px', fontSize: '24px' }}>RSC</h3>
            <p style={{ marginLeft: '18px' }}>La responsabilidad corporativa es inherente a nuestro modelo de negocio, impulsamos la educación financiera y apoyamos la investigación y la cultura.</p>
            <br />
          </Col>
        </Row>
        ) : null
      }

      <div id="job-offer-employer-section" className='mt-5'>
        <Row className='mb-3'>
          <Col>
            <h2>{data.job.employer.name}</h2>
          </Col>
        </Row>

        <Row>
          <Col sm={5} md={4} lg={3} className='job-offer-employer-logo'>
            <img
              src={data.job.employer.logo_url}
              alt={`Logo de empleador ${data.job.employer.name}`}
            />
          </Col>
          <Col sm={7} md={8} lg={9} className='job-offer-employer-description'>
            {data.job.employer.description}
          </Col>
        </Row>

        <Row className='mt-3'>
          <Col xl='6'>
            <a href={utils.getApplyUrl(data.job)} className="btn btn-danger btn-lg">{getApplyText(data.job, labelsLanguage)}</a>
            &nbsp;
            <a href={data.job.employer.website_url} className="btn btn-outline-secondary btn-lg">Ver sitio web</a>
          </Col>
        </Row>

        <NewsletterForm site={data.job.site} location={"job"} />

      </div>

    </React.Fragment>
  )
}

export default Job;