import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Col, Row } from 'react-bootstrap';

import wolData from '../common/wolData';
import LocaleContext from "./localeContext";
import LoadingSpinner from "./loadingSpinner";
import Markup from "./markup";
import EmployerJobs from "./EmployerJobs";
import OpenToHireSection from "./OpenToHireSection";


const getEmployer = (employers, employerUrl) => {
  return employers.find(e => (e.landing_url === employerUrl));
}

const normalizeData = (rawData, employerUrl) => {
  const employer = getEmployer(rawData.employers, employerUrl);
  return employer;
}

const Employer = () => {
  const [employer, setEmployer] = useState(null);
  const { employerId } = useParams();
  const { setShowSwitch } = useContext(LocaleContext);

  useEffect(() => {
    setShowSwitch(false);
  }, [setShowSwitch])

  const getData = async () => {
    try {
      const response = await fetch(wolData.urls.homepageUrl + `/all-employers.json`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('jwt')
        }
      })
      const data = await response.json()
      let cleanData = normalizeData(data, employerId);
      setEmployer(cleanData)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => getData(), [])


  return employer ? (
    <Container
      id="employer-landing-container"
      className="d-flex flex-column align-items-center"
      fluid
    >
      <Col md={9}>
        <Row className="d-flex flex-column align-items-left p-3">
          <h2>{employer.name}</h2>
        </Row>
        <Row>
          <Col md={12} lg={3} className="employer-landing-logo">
            <img
              alt={`Logo de empleador ${employer.name}`}
              src={employer.logo_url}
            />
          </Col>
          <Col md={12} lg={9} className="employer-landing-description">
            <p>{employer.description}</p>
          </Col>
        </Row>
        <br />
        {/* Quote */}
        <Row>
          {(employer.quote) ?
            <p><q style={{ fontStyle: 'italic' }}>{employer.quote}</q></p>
            :
            null
          }
        </Row>

        <Row className="d-flex justify-content-center">
          {(employer.location_link) ?
            <a
              href={employer.location_link}
              target="_blank"
              rel="noreferrer"
              role="button"
              className="btn btn-outline-primary mx-4"
            >
              Ubicación
            </a>
            :
            null
          }

          {(employer.website_url) ?
            <a
              href={employer.website_url}
              target="_blank"
              rel="noreferrer"
              role="button"
              className="btn btn-outline-primary"
            >
              Sitio Web
            </a>
            :
            null
          }
        </Row>
      </Col>

      <Col md={9}>
        <Row>
          <OpenToHireSection employer={employer} />
        </Row>
        <Row>
          <Markup
            data={employer.content}
            className="d-flex flex-column align-items-end"
            options={{
              isEmployer: true,
              customElements: {},
              wrap: content => <div>{content}</div>
            }}
          />
        </Row>
        <Row>
          <EmployerJobs employer={employer} employerId={employerId} />
        </Row>
      </Col>
    </Container>
  ) : <LoadingSpinner />;

}

export default Employer;