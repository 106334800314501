import { withRouter } from "react-router-dom";

const Footer = ({ location }) => {
  const footerClass = (location.pathname === "/") ? "homepage-footer" : "pt-4 my-md-5 pt-md-5 m-5 border-top";
  const footerText = (location.pathname === "/") ? "px-3" : "d-block mb-3 text-muted"

  return (
    <footer className={footerClass}>
      <div className="row">
        <div className="col-12 col-md">
          <small className={footerText}>
            &copy; Work on Law {new Date().getFullYear()}
            {(location.pathname === "/") ? " All rights reserved" : null}
          </small>
        </div>
      </div>
    </footer>
  );
}


export default withRouter(Footer);
